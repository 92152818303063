import { FC } from "react"
import { PageProps, Link } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import AboutMe from "components/about-me"
import * as contentStyle from "styles/content.module.css"

const NotFoundPage: FC<PageProps> = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className={contentStyle.contentSectionWrapper}>
      <section className={contentStyle.contentSection}>
        <header>
          <h1>404: Not Found</h1>
        </header>
        <p>You just hit a route that doesn&#39;t exist... oh, the sadness.</p>
        <p>
          <Link to="/">Go to Home Page</Link>
        </p>
        <hr />
      </section>
    </div>

    <AboutMe />
  </Layout>
)

export default NotFoundPage
